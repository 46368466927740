<template>
  <div id="balance" class="main-content scrollbar-hide" style="height: calc(100vh - 80px)">
    <div class="stripe-panel-wrapper">
      <search-input
          v-model="search"
          :placeholder="'Enter wallet name'"
          @close="search = ''"
      >
        <wallet-dropdown-menu />
        <button v-if="canOrderCard"
                class="btn order-card highlighted"
                @click="orderCard"
        >
          Order a card
        </button>
      </search-input>
    </div>

    <div class="panel-wrapper">
      <h1 class="headline">
        Wallets
      </h1>
    </div>

    <div class="main-content-grid">
      <tabs v-model="active" :items="items" />

      <template v-if="active">
        <!-- Display Active Tab -->
        <template v-if="isActiveTab">
          <div class="grid" v-if="getActiveVisibleItems.length">
            <div class="col-4" v-for="item in getActiveVisibleItems" :key="item.id">
              <wallet-card :item="item" />
            </div>
          </div>

          <div class="grid-hidden-separator" v-if="getActiveVisibleItems.length && getActiveHiddenItems.length">
            <span>Hidden</span>
          </div>

          <div class="grid grid-hidden" v-if="getActiveHiddenItems.length">
            <div class="col-4" v-for="item in getActiveHiddenItems" :key="item.id">
              <wallet-card :item="item" />
            </div>
          </div>
        </template>

        <!-- Display hidden items -->
        <template v-else>
          <div class="grid grid-hidden" v-if="tabItems.length">
            <div class="col-4" v-for="item in tabItems" :key="item.id">
              <wallet-card :item="item" />
            </div>
          </div>
        </template>

        <div v-if="!tabItems.length" v-html="emptyText" class="main-content-grid-empty" />
      </template>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WalletCard from "@/components/wallet/parts/WalletCard";
import SearchInput from "./beneficiary/SearchInput";
import CreateCard from "./wallet/cards/modals/CreateCard";
import WalletDropdownMenu from "./wallet/parts/WalletDropdownMenu";
import Tabs from "../libs/Tabs";
import {FEATURES} from "@/store/modules/features";

export default {
  components: {
    Tabs,
    WalletDropdownMenu,
    WalletCard,
    SearchInput
  },
  data () {
    return {
      active: null,
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions',

      activeBalances: 'getActiveBalances',
      blockedBalances: 'getBlockedBalances',

      canSupport: 'canSupport'
    }),

    isActiveTab () {
      return this.active.id === 'ACTIVE'
    },

    items () {
      return [
        {
          id: 'ACTIVE',
          label: 'Active',
          count: this.searchFilter(this.activeBalances).length,
          items: this.activeBalances
        },
        {
          id: 'BLOCKED',
          label: 'Blocked',
          count: this.searchFilter(this.blockedBalances).length,
          items: this.blockedBalances
        }
      ]
    },

    tabItems () {
      return this.searchFilter(this.active.items)
    },

    canOrderCard () {
      return this.permissions.canCardCreate && this.canSupport(FEATURES.CARD)
    },

    getActiveVisibleItems () {
      return this.tabItems.filter(i => i.isVisible)
    },
    getActiveHiddenItems () {
      return this.tabItems.filter(i => !i.isVisible)
    },
    emptyText () {
      return this.search.length ? 'Wallets not found' : 'Wallets list is empty'
    }
  },
  methods: {
    orderCard () {
      this.$modal.show(CreateCard)
    },

    searchFilter (items) {
      if (!this.search) {
        return items
      }

      return items.filter(i => {
        let string = this.search.toLowerCase().trim()
        let target = i.title.toLowerCase().trim()

        return target.includes(string)
      })
    }
  }
}
</script>

<style lang="less" scoped>
#balance {
  padding-bottom: 25px;

  .main-content-grid-empty {
    text-align: center;
  }
}

.balance-tabs {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.09);

  li {
    padding-bottom: 15px;
    color: rgba(255, 255, 255, .5);
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    bottom: -1px;
    transition: all .3s ease;
    user-select: none;

    span {
      background-color: #2a2a2d;
      border-radius: 8px;
      padding: 0 8px;
      font-size: 13px;
      margin-left: 8px;
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
    &.active {
      color: rgba(255, 255, 255, 1);
      border-color: #FDE46A;
      border-width: 2px;
      padding-bottom: 14px
    }
  }
}

.grid-hidden-separator {
  display: block;
  padding: 30px 0;
  text-align: center;
  position: relative;

  span {
    outline: 8px solid #19191C;
    border-radius: 12px;
    background-color: rgb(48 48 51);
    margin: 0 auto;
    display: inline-block;

    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;

    padding: 5px 10px 4px;
    position: relative;
    z-index: 2;
  }

  &:before {
    content: ' ';
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    top: 42px;

    opacity: 0.09;
    background-color: #FFFFFF;
    z-index: 1;
  }
}

.grid-hidden /deep/ .main-content-grid-item {
  .item-value,
  .item-copy,
  .item-big-copy,
  .item-icon {
    opacity: .5;
  }
}

</style>
