<template>
  <div class="modal basic" v-body-scroll-lock="true">
    <div class="container">
      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <h2 class="modal-title">
        Currency exchange
      </h2>

      <div class="modal-forms-wrapper">
        <div class="fixed-navigation">
          <ol class="fixed-navigation-list">
            <li class="fixed-navigation-item" :class="{active: step === 0}" @click="moveToStep(0)">
              <span>Currency exchange</span>
            </li>
            <li class="fixed-navigation-item" :class="{active: step === 1}" @click="moveToStep(1)">
              <span>Review</span>
            </li>
          </ol>
        </div>
        <div class="modal-scrollable-wrapper">
          <div class="modal-content" v-show="step === 0">
            <form action="/payment/create">
              <div class="modal-content-section">
                <h3 class="modal-content-title">
                  <div>Input information</div>
                </h3>

                <div class="modal-content-inner">
                  <div class="grid">
                    <div class="col-6">
                      <form-group label="Source wallet" :error="errors.debit_wallet_id">
                        <multiselect
                            v-model="form.debit_wallet_id"
                            :allow-empty="false"
                            :show-labels="false"
                            :options="sourceListGrouped"
                            group-values="items"
                            group-label="title"
                            :class="{'valid' : form.debit_wallet_id}"
                            @close="validate(['debit_wallet_id', 'amount'])"
                            label="name"
                        >
                          <template #caret >
                            <div>
                              <div
                                  v-if="sourceListHidden.length"
                                  v-tooltip="{content: `Switch to ${isSourceEyeOpened ? 'hidden' : 'visible'} wallets`}"
                                  class="multiselect-addon multiselect-addon-eye"
                                  :class="{hidden: isSourceEyeOpened}"
                                  @mousedown.prevent="isSourceEyeOpened = !isSourceEyeOpened"
                              />
                              <div class="multiselect__select" />
                            </div>
                          </template>
                          <template #option="{ option }">
                            <div v-if="option.$isLabel" content="option__currency-label">
                              <img class="option__currency-label-img" :src="require(`@/assets/images/currency_flags/${option.$groupLabel}.svg`)" alt="">
                              <span class="option__currency-label-text">{{ option.$groupLabel }}</span>
                            </div>
                            <div v-else class="option__currency">
                              <span class="option__currency-left notranslate">
                                {{ option.title }}
                              </span>
                              <span class="option__currency-right">
                                Balance: {{ option.amount | numeral('0,0.00') }} {{ option.currency.id }}
                              </span>
                            </div>
                          </template>
                          <template #singleLabel="{ option }">
                            <div class="option__currency">
                              <span class="option__currency-left">
                                {{ option.title }}
                              </span>
                            </div>
                          </template>
                        </multiselect>
                        <div class="form-wallet-balance" v-if="form.debit_wallet_id">
                          Balance: <balance-label :amount="form.debit_wallet_id.amount" :currency="form.debit_wallet_id.currency.id" />
                        </div>
                      </form-group>
                    </div>
                    <div class="col-6">
                      <form-group label="Target wallet" :error="errors.credit_wallet_id">
                        <multiselect
                            v-model="form.credit_wallet_id"
                            :allow-empty="false"
                            :show-labels="false"
                            :options="targetListGrouped"
                            group-values="items"
                            group-label="title"
                            :class="{'valid' : form.credit_wallet_id}"
                            @close="validate(['credit_wallet_id', 'debit_wallet_id'])"
                            label="name"
                        >
                          <template #caret >
                            <div>
                              <div
                                  v-if="targetListHidden.length"
                                  v-tooltip="{content: `Switch to ${isTargetEyeOpened ? 'hidden' : 'visible'} wallets`}"
                                  class="multiselect-addon multiselect-addon-eye"
                                  :class="{hidden: isTargetEyeOpened}"
                                  @mousedown.prevent="isTargetEyeOpened = !isTargetEyeOpened"
                              />
                              <div class="multiselect__select" />
                            </div>
                          </template>
                          <template #option="{ option }">
                            <div v-if="option.$isLabel" content="option__currency-label">
                              <img class="option__currency-label-img" :src="require(`@/assets/images/currency_flags/${option.$groupLabel}.svg`)" alt="">
                              <span class="option__currency-label-text">{{ option.$groupLabel }}</span>
                            </div>
                            <div v-else class="option__currency">
                              <span class="option__currency-left notranslate">
                                {{ option.title }}
                              </span>
                              <span class="option__currency-right">
                                Balance: {{ option.amount | numeral('0,0.00') }} {{ option.currency.id }}
                              </span>
                            </div>
                          </template>
                          <template #singleLabel="{ option }">
                            <div class="option__currency">
                              <span class="option__currency-left">
                                {{ option.title }}
                              </span>
                            </div>
                          </template>
                        </multiselect>
                        <div class="form-wallet-balance" v-if="form.credit_wallet_id">
                          Balance: <balance-label :amount="form.credit_wallet_id.amount" :currency="form.credit_wallet_id.currency.id" target />
                        </div>
                      </form-group>
                    </div>
                    <div class="col-6">
                      <form-group :error="errors.amount" label="Amount">
                        <amount-input
                            v-model="form.amount"
                            @change.native="validate(['amount'])"
                            :class="{'valid': form.amount}"
                            name="amount"
                        />
                        <span class="all" v-if="isAllButtonEnabled" @click="setMaxAmount">All</span>
                      </form-group>
                    </div>
                  </div>
                </div>
                <div class="modal-content-inner" v-if="tax && tax.fee && result && result.amount" style="padding: 0">
                  <div class="panel-additional-info">
                    <div class="panel-block" v-if="tax && tax.fee">
                      <span class="panel-block-label">Fee</span>
                      <span class="panel-block-value">
                        {{ tax.fee | numeral('0,0.00') }} {{ tax.currency_id }}
                      </span>
                    </div>
                    <div class="panel-block" v-if="tax && tax.fee && form.debit_wallet_id">
                      <span class="panel-block-label">Total</span>
                      <span class="panel-block-value">
                        {{ tax.total | numeral('0,0.00') }} {{ form.debit_wallet_id.currency.id }}
                      </span>
                    </div>
                    <div class="panel-block" v-if="result && result.rate">
                      <span class="panel-block-label">Exchange rate* </span>
                      <span class="panel-block-value">
                        {{ result.rate }}
                      </span>
                    </div>
                    <div class="panel-block" v-if="result && result.amount && result.currency_id">
                      <span class="panel-block-label">FX resulting quote*</span>
                      <span class="panel-block-value">
                        {{ result.amount | numeral('0,0.00') }} {{ result.currency_id }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="modal-content-bottom-panel">
                  <div class="pull-left">
                    <div class="block-info" v-if="canDisplayFxMessage">*Due to FX market fluctuations resultant amount might marginally differ</div>
                  </div>
                  <p @click="moveToStep(1)" class="modal-content-bottom-panel-copy next-form-button available" data-dismiss="modal" aria-label="Close">
                    <span>Next</span>
                    <img src="@/assets/images/icons/ic_next.svg">
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-content" v-if="step === 1">
            <div class="modal-content-section">
              <h3 class="modal-content-title">
                Source wallet
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-3">
                    <p class="modal-inner-title">
                      Currency
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.debit_wallet_id.currency.id }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="modal-inner-title">
                      Balance
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.debit_wallet_id.amount | numeral('0,0.00') }} {{ form.debit_wallet_id.currency.id }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="modal-inner-title">
                      IBAN
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.debit_wallet_id.mainDetails.iban }}
                    </p>
                  </div>
                </div>
              </div>

              <h3 class="modal-content-title">
                Target wallet
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-3">
                    <p class="modal-inner-title">
                      Currency
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.credit_wallet_id.currency.id }}
                    </p>
                  </div>
                  <div class="col-3">
                    <p class="modal-inner-title">
                      Balance
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.credit_wallet_id.amount | numeral('0,0.00') }} {{ form.credit_wallet_id.currency.id }}
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="modal-inner-title">
                      IBAN
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.credit_wallet_id.mainDetails.iban }}
                    </p>
                  </div>
                </div>
              </div>

              <h3 class="modal-content-title">
                Summary
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-3">
                    <p class="modal-inner-title">
                      Amount
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.amount }} {{ form.debit_wallet_id.currency.id }}
                    </p>
                  </div>
                  <div class="col-3" v-if="tax">
                    <p class="modal-inner-title">
                      Fee
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ tax.fee | numeral('0,0.00') }} {{ tax.currency_id }}
                    </p>
                  </div>
                  <div class="col-6" v-if="tax">
                    <p class="modal-inner-title">
                      Total
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ tax.total | numeral('0,0.00') }} {{ form.debit_wallet_id.currency.id }}
                    </p>
                  </div>
                </div>
              </div>

              <template v-if="isMfaEnabled">
                <h3 class="modal-content-title">
                  Confirmation
                </h3>
                <h4 class="modal-content-subtitle">Please enter the code from Google Authenticator</h4>
                <div class="modal-content-inner">
                  <div class="grid">
                    <div class="col-6">
                      <form-group :error="errors.code" label="2FA Code">
                        <code-input @fulfilled="validate(['code'])" v-model="form.code" />
                      </form-group>
                    </div>
                  </div>
                </div>
              </template>

              <div class="modal-content-bottom-panel">
                <button type="submit" @click="submit()" :disabled="submitDisabled" class="default-button">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/libs/forms/FormGroup'
import { mapState, mapGetters } from 'vuex'
import groupBy from 'lodash/groupBy'
import Multiselect from 'vue-multiselect'
import CodeInput from '@/libs/CodeInput'
import AmountInput from '@/components/modals/AmountInput'
import BalanceLabel from "@/libs/BalanceLabel";

export default {
  name: 'ConversionForm',
  components: {
    AmountInput,
    CodeInput,
    FormGroup,
    Multiselect,
    BalanceLabel
  },
  props: {
    walletId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      step: 0,
      form: {
        amount: '',
        debit_wallet_id: '',
        credit_wallet_id: '',
        code: ''
      },
      errors: {},
      submitDisabled: false,

      isSourceEyeOpened: true,
      isTargetEyeOpened: true
    }
  },
  created () {
    // Preselect wallet
    if (this.walletId) {
      let wallet = this.balances.find(item => {
        return item.id === this.walletId
      })

      if (wallet) {
        this.form.debit_wallet_id = wallet
      }
    }
  },
  watch: {
    'form.debit_wallet_id' (newVal) {
      if (newVal.id === this.form.credit_wallet_id.id) {
        this.form.credit_wallet_id = ''
      }

      this.form.amount = ''
    },
    'errors': {
      handler (newVal) {
        if (newVal?.code && this.form.code.length === 6) {
          this.form.code = ''
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.data,
    }),
    ...mapGetters({
      activeBalances: 'getActiveBalances',
      isMfaEnabled: 'isMfaEnabled'
    }),

    balances () {
      return this.activeBalances.filter(i => {
        return i.permissions.canCurrencyExchange
      })
    },

    /**
     * Display FX Message by condition
     */
    canDisplayFxMessage () {
      return this.form.debit_wallet_id
          && this.form.credit_wallet_id
          && this.form.amount
    },

    /**
     * Return default list filtered by permissions
     *
     * @return {*}
     */
    sourceList () {
      return this.balances
    },

    /**
     * Return only hidden wallets
     *
     * @returns {*}
     */
    sourceListHidden () {
      return this.sourceList.filter(i => !i.isVisible)
    },

    /**
     * Return list of balances for debit wallet
     *
     * @returns {[]}
     */
    sourceListGrouped () {
      const data = []
      const balances = this.sourceList
          .filter(i => this.isSourceEyeOpened ? i.isVisible : !i.isVisible)

      const items = groupBy(balances, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },

    /**
     * Return default list filtered by condition
     * @returns {*}
     */
    targetList () {
      return this.balances.filter(i => i.currencyId !== this.form.debit_wallet_id.currencyId)
    },

    /**
     * Return only hidden wallets
     *
     * @returns {*}
     */
    targetListHidden () {
      return this.targetList.filter(i => !i.isVisible)
    },

    /**
     * Return list of balances for credit wallet
     *
     * @returns {[]}
     */
    targetListGrouped () {
      const data = []
      const balances = this.targetList
          .filter(i => this.isTargetEyeOpened ? i.isVisible : !i.isVisible)

      const items = groupBy(balances, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },

    /**
     * Process data for sending
     * @returns {{amount: (number), credit_wallet_id: (*|string), debit_wallet_id: (*|string)}}
     */
    formData () {
      return {
        amount: this.form.amount,
        debit_wallet_id: this.form.debit_wallet_id ? this.form.debit_wallet_id.id : '',
        credit_wallet_id: this.form.credit_wallet_id ? this.form.credit_wallet_id.id : '',
        code: this.form.code ?? undefined
      }
    },

    asyncFormData () {
      return {
        amount: this.form.amount,
        debit_wallet_id: this.form.debit_wallet_id ? this.form.debit_wallet_id.id : '',
        credit_wallet_id: this.form.credit_wallet_id ? this.form.credit_wallet_id.id : '',
      }
    },

    maxAmountFormData () {
      return {
        debit_wallet_id: this.form.debit_wallet_id?.id || '',
        credit_wallet_id: this.form.credit_wallet_id?.id || '',
      }
    },

    isAllButtonEnabled () {
      return this.form.debit_wallet_id
          && this.form.credit_wallet_id !== ''
          && this.form.amount !== this.form.debit_wallet_id.amount
          && this.maxAmount
    },

  },
  asyncComputed: {
    tax: {
      get () {
        return this.$sdk.exchange
            .getFee(this.asyncFormData)
            .then(response => response.data)
      }
    },
    maxAmount: {
      get () {
        return this.$sdk.exchange
            .getMaxAmount(this.maxAmountFormData)
            .then(response => response.data.maxAmount)
      },
      shouldUpdate () {
        return this.form.debit_wallet_id
            && this.form.credit_wallet_id
      }
    },
    result: {
      get () {
        return this.$sdk.exchange
            .result(this.asyncFormData)
            .then(response => response.data)
      }
    }
  },
  sockets: {
    /** Rerender changed exchange rate and max amount for exchange **/
    'refreshCurrencyExchangeRate' () {
      this.$asyncComputed.result.update()
      this.$asyncComputed.maxAmount.update()
    },
  },
  methods: {
    setMaxAmount () {
      if (this.maxAmount) {
        this.form.amount = this.maxAmount
        this.validate(['amount'])
      }
    },
    moveToStep (index) {
      const attrs = ['amount', 'debit_wallet_id', 'credit_wallet_id']
      this.validate(attrs, this.formData).then(response => {
        const respAttributes = Object.keys(response.data)

        let intersection = attrs.filter(x => respAttributes.includes(x));
        if (intersection.length === 0) {
          this.step = index
        }
      })
    },
    submit () {
      this.submitDisabled = true

      return this.$sdk.exchange.create(this.formData, {
        params: {
          validate: 0
        }
      }).then(response => {
        if (Object.keys(response.data).length > 0) {
          this.errors = []

          for (const attribute in response.data) {
            if (Object.prototype.hasOwnProperty.call(response.data, attribute)) {
              this.$set(this.errors, attribute, response.data[attribute])
            }
          }
        } else {
          this.$emit('close')
          this.$notice.success('Exchange was created successfully')
        }
      }).finally(() => {
        this.submitDisabled = false
      })
    },

    validate (attributes = []) {
      return this.$sdk.exchange.create(this.formData, {
        params: {
          validate: 1
        }
      }).then(response => {
        let attrs = attributes.filter((key) => {
          return !!this.formData[key]
        })

        for (const attribute of attrs) {
          this.$set(this.errors, attribute, response.data[attribute])
        }

        return response
      })
    }
  }
}
</script>

<style lang="less">
.block-info {
  font-size: 12px;
  color: #666;
  line-height: 1.65em;
}
</style>
