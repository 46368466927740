<template>
  <div class="modal basic">
    <div class="top-up-wrap">

      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>

      <div class="top-up">
        <div class="top-up-left">
          <div>
            <h2 class="top-up-title">To pay</h2>

            <div class="top-up-left-amount" ref="amount">
              <fit-text :min="12" :max="60" unit="px">
                {{ form.amount | numeral('0,0.00') }} {{ form.wallet && form.wallet.currencyId }}
              </fit-text>
            </div>

            <img src="@/assets/images/topup/terminal.svg" alt="" class="terminal">
          </div>
          <div>
            <div class="separator" />
            <div class="copyright">Powered by {{ settings.brand_name }}</div>
          </div>
        </div>

        <div class="top-up-right">
          <h2 class="top-up-title">Top up</h2>

          <form-group label="Wallet">
            <multiselect
                v-model="form.wallet"
                :allow-empty="false"
                :show-labels="false"
                :options="balancesList"
                group-values="items"
                group-label="title"
                :class="{'valid' : form.wallet}"
                label="title"
            >
              <template #option="props">
                <div v-if="props.option.$isLabel" content="option__currency-label">
                  <img class="option__currency-label-img" :src="require(`@/assets/images/currency_flags/${props.option.$groupLabel}.svg`)" alt="">
                  <span class="option__currency-label-text">{{ props.option.$groupLabel }}</span>
                </div>
                <div v-else class="option__currency">
                  <span class="option__currency-left notranslate">
                    {{ props.option.title }}
                  </span>
                  <span class="option__currency-right">
                    Balance: {{ props.option.amount | numeral('0,0.00') }} {{ props.option.currency.id }}
                  </span>
                </div>
              </template>
              <template slot="singleLabel" slot-scope="props">
                <div class="option__currency">
                  <span class="option__currency-left notranslate">
                    {{ props.option.title }}
                  </span>
                </div>
              </template>
            </multiselect>
            <div class="form-wallet-balance" v-if="form.wallet">
              Balance: <balance-label :amount="form.wallet.amount" :currency="form.wallet.currency.id" />
            </div>
          </form-group>

          <form-group label="Amount" :error="errors.amount">
            <amount-input
                v-model="form.amount"
                @change.native="validate(['amount'])"
                :class="{'valid': form.amount}"
                name="amount"
            />
            <div class="fee-labels">
              <div v-if="hasFee" class="fee-labels-wrapper">
                <div class="form-wallet-balance">
                  Fee: <balance-label :amount="tax.fee" :currence="tax.currency_id" target />
                </div>
                <div class="form-wallet-balance">
                  Amount received: <balance-label :amount="tax.total" :currence="tax.currency_id" target />
                </div>
              </div>
            </div>
          </form-group>

          <form-group label="Pay with">
            <multiselect
                v-model="topUpType"
                :allow-empty="false"
                :show-labels="false"
                :options="topUpTypeList"
                :class="{ valid: topUpType }"
                label="label"
            >
            </multiselect>
          </form-group>

          <template v-if="isApplePayment">
            <div class="safari-notification">
              <template v-if="!isNotificationVisible">
                <icon name="ic_info_2" class="icon" />
              </template>
              <template v-else>
                <img src="@/assets/images/topup/safari.svg" alt="Safari browser">
              </template>
              This method is only available in the Safari browser
            </div>
          </template>

          <template v-else-if="isKlarnaPayment">
            <div class="klarna-message">
              <img src="@/assets/images/topup/payid.svg" alt="Klarna payments">
            </div>
          </template>

          <template v-else>
            <form-group label="Card information" :error="cardErrors">
              <div class="card-field">
                <span class="number" v-tooltip.bottom="{content: 'Mastercard only'}">
                  <input
                      v-model="form.number"
                      v-mask="'#### #### #### ####'"
                      class="form-control"
                      :class="{valid: form.number}"
                      placeholder="1234 1234 1234 1234"
                      @focusout="validate(['number'])"
                  />
                  <span class="icons" v-if="hasIcons">
                    <img src="@/assets/images/topup/visa.svg" class="icon-visa" v-show="isVisa">
                    <img src="@/assets/images/topup/mastercard.svg" class="icon-mastercard" v-show="isMasterCard">
                  </span>
                </span>
                <span class="date">
                  <input type="text" v-model="form.expire_date" v-mask="'##/##'" class="form-control" :class="{valid: form.expire_date}" placeholder="MM/YY" @focusout="validate(['expireMonth', 'expireYear'])"/>
                </span>
                <span class="cvc">
                  <input type="text" v-model="form.cvc" v-mask="'###'" class="form-control" :class="{valid: form.cvc}" placeholder="CVC" @focusout="validate(['cvc'])" />
                </span>
              </div>
            </form-group>

            <form-group label="Name on card" :error="errors.name">
              <input type="text" v-model="form.name" :class="{valid: form.name}" class="form-control" placeholder="Jotn Smith" @focusout="validate(['name'])">
            </form-group>
            <div class="supported-cards">
              <label>Supported cards</label>
              <img src="@/assets/images/topup/mastercard-lg.svg" alt="MasterCard">
            </div>
          </template>

          <div class="separator" />
          <button
            class="default-button btn-pay"
            :disabled="isSubmitDisabled"
            @click.prevent="submit"
          >
            Pay
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '../../libs/forms/FormGroup'
import Icon from '@/libs/Icon.vue'
import Multiselect from 'vue-multiselect'
import {mapGetters, mapState} from 'vuex'
import groupBy from 'lodash/groupBy'
import FitText from '@/libs/FitText'
import AmountInput from '@/components/modals/AmountInput'
import BalanceLabel from "@/libs/BalanceLabel";
import {FEATURES} from "@/store/modules/features";

export default {
  name: 'TopUp',
  components: {
    AmountInput,
    FormGroup,
    Icon,
    Multiselect,
    FitText,
    BalanceLabel
  },
  props: {
    walletId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      form: {
        amount: '',
        wallet: null,

        // card fields
        number: '',
        cvc: '',
        expire_date: '',
        name: ''
      },
      errors: {},
      loading: false,

      topUpType: null,
    }
  },
  created() {
    // Preselection for top up method
    if (this.topUpTypeList.length) {
      this.topUpType = this.topUpTypeList[0]
    }

    // Preselect wallet
    if (this.walletId) {
      this.form.wallet = this.balances.find(i => {
        return Number(i.id) === Number(this.walletId)
      })
    } else {
      this.form.wallet = this.balancesFiltered[0] ?? null
    }
  },
  asyncComputed: {
    tax: {
      get () {
        return this.$sdk.wallet.getTopUpFee(this.form.wallet.id, {
          amount: this.form.amount,
          type: this.topUpType.id
        }).then(response => {
          return response.data
        })
      },
      shouldUpdate () {
        return this.form.amount
            && this.form.wallet
            && this.form.wallet.id
      },
      default: {
        fee: null,
        currency_id: null
      }
    }
  },
  watch: {
    topUpType () {
      this.errors = {}
    }
  },
  computed: {
    ...mapState({
      settings: state => state.site.settings,
    }),
    ...mapGetters({
      balances: 'getActiveBalances',
      canSupport: 'canSupport',
    }),
    topUpTypeList () {
      return [
        {
          id: 'card',
          label: 'Card',
          method: 'topUp',
          $isDisabled: !this.canSupport(FEATURES.WALLET_TOP_UP_FROM_CARD)
        },
        {
          id: 'apple-pay',
          label: 'Apple Pay',
          method: 'topUpByApplePay',
          $isDisabled: !this.canSupport(FEATURES.WALLET_TOP_UP_FROM_APPLE)
        },
        {
          id: 'klarna',
          label: 'PayID',
          method: 'topUpByKlarna',
          $isDisabled: !this.canSupport(FEATURES.WALLET_TOP_UP_FROM_KLARNA)
        }
      ]
    },
    isSafariBrowser () {
      const hasChromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
      const hasSafariAgent = navigator.userAgent.indexOf("Safari") > -1;

      return !((hasChromeAgent && hasSafariAgent) || !hasSafariAgent)
    },
    isKlarnaPayment () {
      return this.topUpType?.id === 'klarna'
    },
    isApplePayment () {
      return this.topUpType?.id === 'apple-pay'
    },
    isNotificationVisible () {
      return this.isApplePayment && !this.isSafariBrowser
    },
    hasFee () {
      return this.form.wallet
          && this.tax
          && this.tax.fee
          && this.tax.total
          && this.form.amount.length
    },
    balancesFiltered () {
      return this.balances.filter(i => {
        return i.permissions.canTopUp
      })
    },
    balancesList () {
      const data = []
      const items = groupBy(this.balancesFiltered, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },
    isVisa () { // 4165 9870 1773 4915
      let regexp = /^4[0-9]{6,}$/
      return regexp.test(this.form.number)
    },
    isMasterCard () { // 5354 5670 5005 3353
      let regexp = /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/
      return regexp.test(this.form.number)
    },
    isSubmitDisabled () {
      return (this.isApplePayment && !this.isSafariBrowser)
          || this.loading
          || !this.form.wallet
          || !this.topUpType
    },
    hasIcons () {
      return this.isVisa || this.isMasterCard
    },
    formData () {
      let expire = this.form.expire_date.split('/')

      return {
        'card': {
          name: this.form.name,
          amount: this.form.amount,
          number: this.form.number,
          expireMonth: expire[0],
          expireYear: expire[1],
          cvc: this.form.cvc,
          browserData: this.browserData
        },
        'apple-pay': {
          amount: this.form.amount
        },
        'klarna': {
          amount: this.form.amount
        }
      }[this.topUpType.id]
    },
    browserData () {
      return {
        screenColorDepth: window.screen.colorDepth,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowInnerWidth: window.innerWidth,
        windowInnerHeight: window.innerHeight,
        navigatorLanguage: navigator.language,
        timezoneOffset: (new Date()).getTimezoneOffset(),
        navigatorJavaEnabled: window.navigator.javaEnabled(),
        navigatorUserAgent: navigator.userAgent
      }
    },
    cardErrors () {
      return [
        ...this.errors.number || [],
        ...this.errors.expireMonth  || [],
        ...this.errors.expireYear || [],
        ...this.errors.cvc || []
      ]
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.$sdk.wallet[this.topUpType.method](this.form.wallet.id, this.formData, 0).then(response => {
        let status = response.data.status
        if (status) {
          if (status === '3DS') {
            let url = new URL(response.data.url)
            let method = response.data.method;
            let params = response.data.params;

            if (method.toLowerCase() === 'get') {
              for (let key in params) {
                url.searchParams.append(key, params[key])
              }

              // Fix for safari popup
              window.location.assign(url.href)
            } else {
              // @todo: May be problem
              this.$notice.error('Something was wrong')
            }

          }
          if (status === 'OK') {
            this.$notice.success(response.data.message)
          }
          if (status === 'ERROR') {
            this.$notice.error(response.data.message)
          }

          this.$emit('close')
        } else {
          this.errors = response.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    validate (attributes = []) {
      if (this.form.wallet) this.$sdk.wallet[this.topUpType.method](this.form.wallet.id, this.formData, 1).then(response => {
        /** Set all errors **/
        for (const attribute of attributes) {
          if (response.data[attribute]) {
            this.$set(this.errors, attribute, response.data[attribute])
          } else {
            this.$delete(this.errors, attribute)
          }
        }
      })
    }
  }
}

</script>

<style lang="less" scoped>
.top-up-wrap {
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}
.top-up {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .payment-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      flex-direction: row;
      align-items: center;

      .btn {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        user-select: none;
        border: 1px solid transparent;

        &:hover,
        &.active {
          background-color: #262629;
          color: #FFFFFF;
        }

        &:hover:not(.active) {
          opacity: .8;
        }

        &.active {
          border: 1px solid #FDE46A;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  .safari-notification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 30px;
    line-height: 1.45em;
    gap: 10px;

    height: 246px;
    background-color: #2626292e;
    border-radius: 2px;
    border: 1px dashed #262629;

    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, .6);

    .icon {
      vertical-align: middle;
      margin-right: 6px;
      fill: #FDE46A;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    opacity: 0.1;
    background-color: #FFFFFF;
    margin: 40px 0;
  }

  &-title {
    color: #FFFFFF;

    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    margin-bottom: 30px;
  }

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 360px;
    box-sizing: content-box;
    padding-right: 80px;

    .terminal {
      margin: 50px auto 0;
      display: block;
    }

    .copyright {
      color: rgba(255, 255, 255, .4);
      line-height: 36px;
    }

    &-amount {
      color: #FFFFFF;
      //font-size: 60px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 72px;
      transition: all .2s ease;
    }
  }

  &-right {
    width: 320px;
    box-sizing: content-box;

    border-left: 1px solid rgba(255, 255, 255, .1);
    padding-left: 80px;

    .fee-labels {
      margin-top: 7px;
      line-height: 13px;
      height: 13px;

      &-wrapper {
        display: flex;
        gap: 20px;
      }

      .form-wallet-balance {
        margin: 0;
      }
    }
  }

  @media all and (max-width: 900px) {
    grid-template-columns: 1fr;

    &-left {
      display: none;
    }
    &-right {
      border-left: 0;
      padding-left: 0;
    }
  }
}

.form-group {
  margin-bottom: 20px;

  .form-control {
    border-color: #909091;
  }
}
//
//.container {
//  max-width: 900px;
//}

.card-field {
  .number { grid-area: number; position: relative; }

  .date {
    grid-area: expire;
    position: relative;
    top: -1px;

    border-radius: 0 0 0 2px;
  }

  .cvc {
    grid-area: cvc;
    position: relative;
    top: -1px;
    margin-left: -1px;
    width: calc(100% + 1px);

    border-radius: 0 0 2px 0;

    &:before {
      content: ' ';
      display: block;
      width: 28px;
      height: 22px;
      background-image: url("../../assets/images/topup/card.svg");
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -11px;
    }
  }

  .icons {
    display: flex;
    grid-gap: 10px;
    position: absolute;
    width: 24px;
    height: 20px;
    top: 50%;
    margin-top: -12px;
    right: 13px;
  }

  .card-visa {
    background-image: url("../../assets/images/topup/visa.svg");
  }
  .card-mastercard {
    background-image: url("../../assets/images/topup/mastercard.svg");
  }

  display: grid;
  grid-template-areas:
    "number number"
    "expire cvc";
}

.supported-cards {
  label {
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
    opacity: 0.6;
  }
}

.btn-pay {
  display: block;
  width: 100%;
  min-height: 38px;
}

.block-center {
  margin: 0 auto;
}

.modal.basic .menu-close {
  top: 40px;
  right: 40px;
}

.klarna-message {
  height: 246px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 312px;
  }
}
</style>
