<template>
  <div class="wallet-dropdown" v-click-outside="close">
    <button class="wallet-dropdown-button" :class="{'active': dropdownOpen}" @click="toggleDropdown">
      <icon name="visibility_active" class="wallet-dropdown-button-icon"/>
    </button>
    <slide-y-up-transition :duration="150">
      <div v-if="dropdownOpen" class="wallet-dropdown-content">

        <div class="wallet-dropdown-content-search">
          <label>
            <icon name="ic_search" />
          </label>
          <input type="text" placeholder="Search" ref="searchRef" v-model="search" />
          <div v-if="search.length > 0" @click.stop="cleanSearchInput" class="menu-close">
            <icon name="ic_close_rounded" />
          </div>
        </div>

        <div class="wallet-dropdown-content-list scrollbar-hide">
          <template v-if="balancesGroupedList.length">
            <div v-for="(currency, index) in balancesGroupedList" :key="index">
              <div class="currency-group-flag">
                <flag :name="currency.title" />
                <span>{{ currency.title }}</span>
              </div>
              <div
                  v-for="(item) in currency.items"
                  :class="['wallet-item', permissions.canWalletEdit && 'wallet-item-hovered']"
                  :key="item.id"
                  @click="toggleWalletSelection(item)"
              >
                <div class="wallet-item-info">
                  <icon v-if="item.isCardWallet" name="p_card" class="wallet-item-icon" />
                  <div class="wallet-item-title">
                    {{ item.title }}
                  </div>
                  <div class="wallet-item-amount">
                    Balance: <span>{{ item.amount | numeral('0,0.00') }} {{ item.currency.id }} </span>
                  </div>
                </div>
                <div class="checkbox-container">
                  <input
                      type="checkbox"
                      :disabled="!permissions.canWalletEdit"
                      :checked="isChecked(item)"
                      @click.prevent.stop
                  >
                  <span class="checkmark" />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="wallet-item-empty">Not found</div>
          </template>
        </div>
      </div>
    </slide-y-up-transition>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import Flag from '@/libs/Flag'
import Icon from "@/libs/Icon";
import {mapGetters} from "vuex";
import ClickOutside from 'vue-click-outside'
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'WalletDropdownMenu',
  components: {
    Icon,
    Flag,
    SlideYUpTransition
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      dropdownOpen: false,
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      activeBalances: 'getActiveBalances',
      permissions: 'permissions'
    }),
    filteredList () {
      return this.filterList(this.activeBalances, this.search)
    },
    balancesGroupedList () {
      const data = []
      const items = groupBy(this.filteredList, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },
  },
  watch: {
    dropdownOpen (newVal) {
      newVal && this.focusOnInput()
    }
  },
  methods: {
    filterList (list, searchString) {
      if (searchString === '') {
        return list
      }
      let string = searchString.toLowerCase().trim()

      return list.filter(i => {
        if (i.title) {
          let target = i.title.toLowerCase().trim()
          return target.includes(string)
        }
      })
    },
    toggleDropdown () {
      this.dropdownOpen = !this.dropdownOpen
    },
    close () {
      this.dropdownOpen = false
      this.cleanSearchInput()
    },
    focusOnInput () {
      this.$nextTick(() => {
        this.$refs.searchRef.focus()
      })
    },
    cleanSearchInput () {
      this.search = ''
    },
    isChecked (item) {
      return item.isVisible
    },
    toggleWalletSelection (item) {
      if (this.permissions.canWalletEdit) {
        this.$sdk.wallet.update(item.id, {
          isVisible: !item.isVisible
        })
      }
    },
  }
}
</script>

<style lang="less">
.wallet-dropdown {
  position: relative;

  &-button {
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-weight: 500;
    border-radius: 2px;
    background-color: #262629;
    height: 100%;

    &-icon {
      path,
      ellipse {
        stroke: #FFFFFF;
        opacity: 0.7;
      }
    }

    &:hover,
    &.active {
      background-color: #FFFFFF;

      path,
      ellipse {
        stroke: #19191C;
        opacity: 1;
      }
    }
  }

  &-content {
    display: block;
    position: absolute;
    background-color: #19191C;
    width: 375px;
    overflow: auto;
    border: 1px solid rgba(255,255,255,0.08);
    border-radius: 2px;
    z-index: 3;
    top: 43px;
    right: 0;

    @media all and (max-width: 768px) {
      width: 335px;
    }

    &-search {
      display: flex;
      align-items: center;
      max-height: 45px;
      padding: 10px 16px;
      border-bottom: 1px solid rgba(255,255,255,0.08);

      &.active {
        min-height: 45px;
      }
      input {
        margin-left: 8px;
        width: 100%;
        background-color: transparent;
        border: none;
        color: #fff;
        font-size: 15px;
      }

      .menu-close {
        height: auto;

        svg {
          width: 20px;
          height: 20px;
          fill: #FFFFFF;
          opacity: 50%;
        }
      }
    }

    &-list {
      margin: 16px 0 0;
      overflow: auto;
      max-height: 440px;

      >div {
        margin-bottom: 16px;
      }

      .currency-group-flag {
        display: flex;
        align-items: center;
        padding: 4px 16px;

        span {
          margin-left: 12px;
          font-size: 15px;
          line-height: 18px;
        }
      }

      .wallet-item {
        display: flex;
        position: relative;
        padding: 10px 16px 10px 52px;
        align-items: center;
        justify-content: space-between;

        &-info {
          overflow: hidden;
        }

        &-icon {
          position: absolute;
          fill: #FFF;
          opacity: .2;

          top: 7px;
          left: 16px;
        }

        &-title {
          margin-right: 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 15px;
          font-weight: 500;
          line-height: 18px;
        }

        &-amount {
          margin-top: 5px;
          color: #FFFFFF80;
          font-size: 13px;
          line-height: 16px;

          span {
            font-weight: 500;
            color: #FDE46A;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, .1);
        }

        &-hovered {
          &:hover {
            background-color: #FDE46A;

            .wallet-item-icon {
              fill: #19191C;
              opacity: 1;
            }

            .wallet-item-title {
              color: #19191C;
            }

            .wallet-item-amount {
              color: #19191C80;

              span {
                color: #19191C;
              }
            }
          }
        }
      }

      .wallet-item-empty {
        padding: 0 16px;
        font-size: 14px;
      }
    }
  }

  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: auto;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #908D9760;
  }

  /* When the checkbox is checked, add a green background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #82C16C;
    border: none;
  }

  /* When the checkbox is disabled */
  .checkbox-container input {
    &:disabled:checked ~ .checkmark {
      background-color: #3E3E42;
      border-color: #908D9760;
    }

    &:disabled ~ .checkmark {
      cursor: not-allowed;

      &:after {
        border-color: #7C7C7E;
      }
    }
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

</style>
