<template>
  <transition name="tab-change" mode="out-in">
    <div class="main-content-grid-item"
         :class="{opened: hovered}"
         @mouseenter="hovered = true"
         @mouseleave="hovered = false"
         @click="hovered = true"
    >

      <icon v-if="item.isCardWallet"   name="p_card" class="wallet-card-ic wallet-card-ic-card" />
      <icon v-if="item.status === 'ACTIVE' && !item.isVisible" name="ic_visibility_off" class="wallet-card-ic wallet-card-ic-eye"/>

      <div class="item-icon">
        <flag :name="item.currency.id" />
      </div>
      <p class="item-value">
        {{ item.amount | numeral('0,0.00') }}
      </p>
      <p class="item-copy">
        {{ item.currency.name }} (<span class="notranslate">{{ item.currency.id }}</span>)
      </p>
      <p class="item-big-copy notranslate">
        <span>{{ item.title }}</span>
      </p>
      <div class="main-content-expanded-options">
        <div class="options-wrapper">
          <div class="expanded-options-item" v-if="canTopUp">
            <button @click.stop="makeTopUp(item.id)">Top up</button>
          </div>
          <div class="expanded-options-item" v-if="canPay">
            <button @click.stop="makePayment(item.id)">Make payment</button>
          </div>
          <div class="expanded-options-item" v-if="canMakeConversions">
            <button @click.stop="makeConversion(item.id)">Currency exchange</button>
          </div>
          <div class="expanded-options-item">
            <router-link :to="{name: 'WalletView', params: {id: item.id}}" custom v-slot="{ navigate }">
              <button @click.stop="navigate" class="v-align">
                <span>Go to wallet</span>
                <icon name="ic_right" />
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Flag from '@/libs/Flag'
import ConversionForm from "../../modals/ConversionForm";
import PaymentCreateModal from "../../modals/PaymentCreateModal";
import TopUp from "../../modals/TopUp";

import { mapGetters } from 'vuex'
import Icon from "@/libs/Icon";

export default {
  name: 'WalletCard',
  components: {
    Icon,
    Flag
  },
  props: ['item'],
  data () {
    return {
      hovered: false
    }
  },
  computed: {
    ...mapGetters([
        'permissions',
        'hasTopUpFeature'
    ]),
    canTopUp () {
      return this.permissions.canWalletTopUp
          && this.item.permissions.canTopUp
          && this.hasTopUpFeature
    },
    canMakeConversions() {
      return this.permissions.canCurrencyExchangeCreate
          && this.item.permissions.canCurrencyExchange
    },
    canPay () {
      return this.item.permissions.canPay
          && (this.permissions.canPaymentCreate || this.permissions.canPaymentDraftCreate)
    }
  },
  methods: {
    makeTopUp(id) {
      this.$modal.show(TopUp, {
        walletId: id
      })
    },
    makeConversion(id) {
      this.$modal.show(ConversionForm, {
        walletId: id
      })
    },
    makePayment (id) {
      this.$modal.show(PaymentCreateModal, {
        walletId: id
      })
    },
  }
}
</script>

<style lang="less" scoped>
.main-content-grid-item.opened {
  pointer-events: initial;
}

.v-align {
  position: relative;

  svg {
    width: 18px;
    margin-left: 10px;
    position: absolute;

    right: -25px;
    top: -6px;

    fill: #FFF;
  }

  &:hover {
    svg {
      fill: #FDE46A;
    }
  }
}

.wallet-card-ic {
  position: absolute;
  fill: #FFF;
  opacity: .5;

  width: 24px;
  height: 24px;

  &-card { top: 17px; right: 17px }
  &-eye  { top: 17px; left: 17px }
}

.color-marker {
  opacity: .2;

  background-color: #FFF;
  border-radius: 50%;

  width: 12px;
  height: 12px;
  position: relative;
  margin-right: 5px;
  display: inline-block;
}
</style>
