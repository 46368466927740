import { render, staticRenderFns } from "./CreateCard.vue?vue&type=template&id=bd05e752&scoped=true&"
import script from "./CreateCard.vue?vue&type=script&lang=js&"
export * from "./CreateCard.vue?vue&type=script&lang=js&"
import style0 from "./CreateCard.vue?vue&type=style&index=0&id=bd05e752&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd05e752",
  null
  
)

export default component.exports