<template>
  <div class="modal basic" v-body-scroll-lock="true">
    <div class="container">
      <div @click="$emit('close')" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <h2 class="modal-title">
        Order a card
      </h2>

      <div class="modal-forms-wrapper">
        <div class="fixed-navigation">
          <ol class="fixed-navigation-list">
            <li class="fixed-navigation-item" v-for="(item, index) in filteredMenuItems" :class="{active: item.step === step, completed: (item.isCompleted() && (item.step !== 'finish'))}" :key="index" @click="moveToStep(item.step)">
              <span>{{ item.name }}</span>
            </li>
          </ol>
        </div>
        <div class="modal-scrollable-wrapper">
          <div class="modal-content">

            <div class="modal-content-section" v-if="step === 'card_account'">
              <h3 class="modal-content-title">
                Select or create card wallet
              </h3>
              <div class="modal-content-inner">

                <div class="grid">
                  <div class="col-12" v-if="hasCardWallets">

                    <div class="card-action-type">
                      <label class="common-custom-radio white">
                        <input type="radio" v-model="form.type" name="type" value="select"/>
                        <span class="common-custom-radio-text">Select a card account from the list</span>
                      </label>

                      <span class="radio-or">or</span>

                      <label class="common-custom-radio white">
                        <input type="radio" v-model="form.type" name="type" value="new"/>
                        <span class="common-custom-radio-text">Create a new card wallet</span>
                      </label>
                    </div>

                  </div>
                  <div class="col-12" v-if="hasCardWallets">
                    <hr class="dashed" />
                  </div>
                  <template v-if="form.type === 'select' && hasCardWallets">

                    <div class="col-6">
                      <form-group label="Card wallet" :error="errors.wallet_id">
                        <multiselect
                            v-model="form.wallet"
                            :allow-empty="false"
                            :show-labels="false"
                            :options="cardWallets"
                            :class="{'valid' : form.wallet}"
                            @close="validate(['wallet_id'])"
                            label="title"
                        />
                        <div class="form-wallet-balance" v-if="form.wallet">
                          Balance: <span class="yellow-colored">{{ form.wallet.amount | numeral('0,0.00') }} {{ form.wallet.currency.id }}</span>
                        </div>
                      </form-group>
                    </div>

                  </template>
                  <template v-else>

                    <div class="col-6">
                      <form-group label="Wallet name" :error="errors.wallet_description">
                        <input type="text" v-model="form.wallet_name" class="form-control" :class="{'valid' : form.wallet_name}" placeholder="Enter name (optional)" @change="validate(['wallet_description'])" />
                      </form-group>
                    </div>

                  </template>
                </div>

              </div>
              <div class="modal-content-bottom-panel">
                <button @click="goNextStep" :disabled="!getMenuItem('card_account').isCompleted()" class="modal-content-bottom-panel-copy next-form-button">
                  <span>{{ getNextStep('card_account').name }}</span>
                  <img src="@/assets/images/icons/ic_next.svg">
                </button>
              </div>
            </div>
            <div class="modal-content-section" v-if="step === 'card_type'">
              <h3 class="modal-content-title">
                What card do you want?
              </h3>
              <div class="modal-content-inner">

                <ul class="choose-card">
                  <li v-for="(card, index) in availableCards" :key="card.title" :class="{active: form.card_type === card.id}">
                    <label class="card-item">
                      <div class="card-item-img">
                        <card :type="card.id" />
                        <span class="card-item-price">
                          <span>Price:</span>&nbsp;
                          <span class="yellow-colored">{{ card.price }} {{ card.currency_id }}</span>
                        </span>
                      </div>
                      <div>
                        <div class="card-item-title">
                          <span class="card-checkbox">
                            <input type="radio" v-model="form.card_type" :value="card.id"/>
                            <span class="card-checkbox-marker" />
                          </span>
                          <span>{{ card.label }} card</span>
                        </div>
                        <ul class="card-item-benefits">
                          <li v-for="item in card.items" :key="item">
                            {{ item }}
                          </li>
                        </ul>
                      </div>
                    </label>

                    <hr class="dashed" v-if="index < availableCards.length - 1">
                  </li>
                </ul>

              </div>

              <div class="modal-content-bottom-panel">
                <button @click="goNextStep" :disabled="!getMenuItem('card_type').isCompleted()" class="modal-content-bottom-panel-copy next-form-button">
                  <span>{{ getNextStep('card_type').name }}</span>
                  <img src="@/assets/images/icons/ic_next.svg">
                </button>
              </div>
            </div>
            <div class="modal-content-section" v-if="step === 'address'">
              <h3 class="modal-content-title">
                Parcel recipient info
              </h3>

              <div class="modal-content-inner">

                <div class="grid">
                  <div class="col-6">
                    <form-group label="First name" :error="errors.first_name">
                      <input type="text" class="form-control" v-model="form.first_name" :class="{'valid': form.first_name}" @focusout="validate(['first_name'])"/>
                    </form-group>
                  </div>
                  <div class="col-6">
                    <form-group label="Last name" :error="errors.last_name">
                      <input type="text" class="form-control" v-model="form.last_name" :class="{'valid': form.last_name}" @focusout="validate(['last_name'])"/>
                    </form-group>
                  </div>
                  <div class="col-6">
                    <form-group label="Phone" :error="errors.phone">
                      <input type="text" class="form-control" v-model="form.phone" :class="{'valid': form.phone}"  @focusout="validate(['phone'])"/>
                    </form-group>
                  </div>
                </div>
              </div>

              <h3 class="modal-content-title" style="margin-top: 30px">
                Address and delivery method
              </h3>

              <div class="modal-content-inner">

                <div class="modal-inside" style="display: none">
                  <div class="modal-inside-title">Same as business address?</div>
                  <div class="modal-inside-subtitle">Company number 11347482. Level 18, 40 Bank Street, Canary Wharf, E14 5NR, London, United Kingdom.</div>
                  <div class="modal-inside-buttons">
                    <button type="button" class="default-button default-button-white">No</button>
                    <button type="button" class="default-button">Yes</button>
                  </div>
                </div>

                <div class="grid">
                  <div class="col-6">

                    <form-group label="Country" :error="errors.country_id">
                      <multiselect v-model="form.delivery_country"
                                   :class="{'valid': form.delivery_country, 'notranslate': true}"
                                   :options="countries"
                                   :allow-empty="false"
                                   :show-labels="false"
                                   :label="'label'"
                                   @close="validate(['country_id'])"
                      />
                    </form-group>

                  </div>
                  <div class="col-6">
                    <form-group label="City" :error="errors.city">
                      <input type="text" class="form-control" v-model="form.delivery_city" :class="{'valid': form.delivery_city}"  @focusout="validate(['city'])"/>
                    </form-group>
                  </div>
                  <div class="col-6">
                    <form-group label="Street" :error="errors.street">
                      <input type="text" class="form-control" v-model="form.delivery_street" :class="{'valid': form.delivery_street}" @focusout="validate(['street'])"/>
                    </form-group>
                  </div>
                  <div class="col-6">
                    <form-group label="House/Apartments" :error="errors.house">
                      <input type="text" class="form-control" v-model="form.delivery_house" :class="{'valid': form.delivery_house}" @focusout="validate(['house'])"/>
                    </form-group>
                  </div>
                  <div class="col-6">
                    <form-group label="Postal code" :error="errors.postal_code">
                      <input type="text" class="form-control" v-model="form.delivery_postal_code" :class="{'valid': form.delivery_postal_code}" @focusout="validate(['postal_code'])"/>
                    </form-group>
                  </div>
                  <div class="col-6">
                    <form-group label="Delivery method" :error="errors.delivery_type">
                      <multiselect :options="deliveries" v-model="form.delivery_type" :class="{'valid': form.delivery_type}" :allow-empty="false" :show-labels="false" @close="validate(['delivery_type'])">
                        <template #option="{ option }">
                          <div class="delivery">
                            <div class="delivery-main">{{ option.label }}</div>
                            <div class="delivery-desc">{{ option.time_label }} = {{ option.price }} {{ option.currency_id }}</div>
                          </div>
                        </template>
                        <template #singleLabel="{ option }">
                          <div class="delivery">
                            <div class="delivery-main">{{ option.label }}</div>
                            <div class="delivery-desc">{{ option.time_label }} = {{ option.price }} {{ option.currency_id }}</div>
                          </div>
                        </template>
                      </multiselect>
                    </form-group>
                  </div>
                </div>

              </div>

              <div class="modal-content-bottom-panel">
                <button @click="goNextStep" :disabled="!getMenuItem('address').isCompleted()" class="modal-content-bottom-panel-copy next-form-button">
                  <span>{{ getNextStep('address').name }}</span>
                  <img src="@/assets/images/icons/ic_next.svg">
                </button>
              </div>

            </div>
            <div class="modal-content-section" v-if="step === 'payments'">
              <h3 class="modal-content-title">
                Payment
              </h3>
              <div class="modal-content-subtitle">Select the wallet from which you want to pay for the card.</div>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-6">

                    <form-group label="Pay with" :error="errors.fee_wallet_id">
                      <multiselect
                          v-model="form.payment_wallet"
                          :allow-empty="false"
                          :show-labels="false"
                          :options="balancesList"
                          group-values="items"
                          group-label="title"
                          :class="{'valid' : form.payment_wallet}"
                          @close="validate(['fee_wallet_id'])"
                          label="title"
                          autocomplete="off"
                      >
                        <template #option="{ option }">
                          <div v-if="option.$isLabel" content="option__currency-label">
                            <img class="option__currency-label-img" :src="require(`@/assets/images/currency_flags/${option.$groupLabel}.svg`)" alt="">
                            <span class="option__currency-label-text">{{ option.$groupLabel }}</span>
                          </div>
                          <div v-else class="option__currency">
                            <span class="option__currency-left notranslate">
                              {{ option.title }}
                            </span>
                            <span class="option__currency-right">
                              Balance: {{ option.amount | numeral('0,0.00') }} {{ option.currency.id }}
                            </span>
                          </div>
                        </template>
                        <template #singleLabel="{ option }">
                          <div class="option__currency">
                            <span class="option__currency-left">
                              {{ option.title }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                      <div class="form-wallet-balance" v-if="form.payment_wallet">
                        Balance: <balance-label :amount="form.payment_wallet.amount" :currency="form.payment_wallet.currency.id" />
                      </div>
                    </form-group>

                  </div>
                </div>
              </div>

              <h3 class="modal-content-title">
                Create a static password
              </h3>
              <div class="modal-content-subtitle">
                Password must contain at least 8 characters, include at least one capital letter, one number and a special symbol.
              </div>
              <div class="modal-content-inner">

                <div class="grid">
                  <div class="col-6">

                    <form-group label="Static password" :error="errors.password">
                      <password-field autocomplete="new-password" v-model="form.static_password" @focusout="validate(['password'])" />
                      <div class="st-pwd-text">Example: <span class="yellow-colored">qWerty99#!</span></div>
                    </form-group>

                  </div>
                </div>

              </div>

              <div class="modal-content-bottom-panel">
                <button @click="goNextStep" :disabled="!getMenuItem('payments').isCompleted()" class="modal-content-bottom-panel-copy next-form-button">
                  <span>{{ getNextStep('payments').name }}</span>
                  <img src="@/assets/images/icons/ic_next.svg">
                </button>
              </div>
            </div>
            <div class="modal-content-section" v-if="step === 'finish'">

              <h3 class="modal-content-title">
                Card info
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-4">

                    <p class="modal-inner-title">
                      Card wallet
                    </p>
                    <p class="modal-inner-copy notranslate">
                      {{ form.wallet ? form.wallet.title : form.wallet_name || notSetLabel }}
                    </p>

                  </div>
                  <div class="col-4">

                    <p class="modal-inner-title">
                      Card type
                    </p>
                    <p class="modal-inner-copy notranslate">
                      <span v-if="form.card_type" class="card-review">
                        <card :type="form.card_type" />
                        {{ geViewCardName(form.card_type) }}
                      </span>
                      <span v-else>
                        {{ notSetLabel }}
                      </span>
                    </p>

                  </div>
                  <div class="col-4" v-if="phone">
                    <p class="modal-inner-title">Phone</p>
                    <p class="modal-inner-copy notranslate">{{ phone }}</p>
                  </div>
                </div>
              </div>

              <template v-if="getMenuItem('address').isVisible()">
                <h3 class="modal-content-title">
                  Address and delivery
                </h3>
                <div class="modal-content-inner">
                  <div class="grid">
                    <div class="col-4">

                      <p class="modal-inner-title">
                        First name
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.first_name || notSetLabel }}
                      </p>

                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        Last name
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.last_name || notSetLabel }}
                      </p>

                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        Phone
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.phone || notSetLabel }}
                      </p>

                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        Country
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.delivery_country ? form.delivery_country.label : notSetLabel}}
                      </p>

                    </div>
                    <div class="col-4">
                      <p class="modal-inner-title">
                        City
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.delivery_city || notSetLabel }}
                      </p>
                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        Street
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.delivery_street || notSetLabel }}
                      </p>

                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        House/Apartments
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.delivery_house || notSetLabel }}
                      </p>

                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        Postal code
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.delivery_postal_code || notSetLabel }}
                      </p>

                    </div>
                    <div class="col-4">

                      <p class="modal-inner-title">
                        Delivery
                      </p>
                      <p class="modal-inner-copy notranslate">
                        {{ form.delivery_type ? form.delivery_type.label : notSetLabel }}
                      </p>

                    </div>
                  </div>
                </div>
              </template>

              <h3 class="modal-content-title">
                Payment
              </h3>
              <div class="modal-content-inner">
                <div class="grid">
                  <div class="col-4">

                    <p class="modal-inner-title">
                      Pay with
                    </p>
                    <p class="modal-inner-text notranslate">
                      <template v-if="form.payment_wallet">
                        <span class="wallet-flag">
                          <flag :name="form.payment_wallet.currency.id" />
                          <span>{{ form.payment_wallet.currency.name }}</span>
                        </span>
                      </template>
                      <template v-else>
                        {{ notSetLabel }}
                      </template>
                    </p>

                  </div>
                  <div class="col-4">
                    <p class="modal-inner-title">
                      Balance
                    </p>
                    <p class="modal-inner-copy notranslate">
                      <template v-if="form.payment_wallet">
                        {{ form.payment_wallet.amount | numeral('0,0.00') }} {{ form.payment_wallet.currency.id }}
                      </template>
                      <template v-else>{{ notSetLabel }}</template>
                    </p>
                  </div>
                </div>
              </div>

              <div class="modal-content-bottom-panel">
                <div class="pull-left">
                  <div class="panel-additional-info for-create-form">
                    <div class="panel-block" v-if="tax.card && tax.card.length">
                      <span class="panel-block-label">Card price</span>
                      <span class="panel-block-value" v-if="tax.card[0]">{{ tax.card[0].amount }} {{ tax.card[0].currency_id }}</span>
                    </div>
                    <div class="panel-block" v-if="tax.delivery && tax.delivery.length">
                      <span class="panel-block-label">Delivery price</span>
                      <span class="panel-block-value" v-if="tax.delivery[0]">{{ tax.delivery[0].amount }} {{ tax.delivery[0].currency_id }}</span>
                    </div>
                    <div class="panel-block" v-if="tax.total && tax.total.length">
                      <span class="panel-block-label">Total</span>
                      <span class="panel-block-value">
                        <span v-if="tax.total[0]">{{ tax.total[0].amount }} {{ tax.total[0].currency_id }}</span>
                        <span v-if="tax.total[1]" class="white with-devider">{{ tax.total[1].amount }} {{ tax.total[1].currency_id }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <button class="default-button" :disabled="!fullCompleted || submitFormInProgress" @click="submit">Order a card</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import FormGroup from "@/libs/forms/FormGroup";
import Card from "@/components/wallet/cards/parts/Card";
import Flag from "@/libs/Flag";
import { mapGetters } from "vuex";
import groupBy from 'lodash/groupBy'
// import ColorPalette from "@/libs/ColorPalette";
import debounce from 'lodash/debounce'
import ConfirmModal from "../../../modals/ConfirmModal";
import BalanceLabel from "@/libs/BalanceLabel";
import PasswordField from "../../../../libs/PasswordField";

export default {
  name: 'CardOrderModal',
  components: {
    PasswordField,
    // ColorPalette,
    Card,
    Flag,
    Multiselect,
    FormGroup,
    BalanceLabel,
  },
  props: ['id'],
  data () {
    return {
      /** Form data fields */
      form: {
        type: null,

        // color: '#FF9F0A',
        wallet: null,
        wallet_name: null,

        delivery_country: null,
        delivery_city: null,
        delivery_street: null,
        delivery_house: null,
        delivery_postal_code: null,
        delivery_type: null,

        first_name: null,
        last_name: null,
        phone: null,

        card_type: null,

        payment_wallet: null,
        static_password: null,
      },

      step: null,
      errors: {},

      deliveries: [],
      countries: [],
      phone: null,
      cards: [],
      cardsAdditional: {
        Virtual: {
          items: [
            'Versatile and eco-friendly;',
            'Stay in control of funds flow;',
            'Safeguarded with leading bank solutions;',
            'Pay your bills remotely;',
            'Extra-secured transactions.'
          ],
          is_visible: this.wallet ? this.wallet.permissions.canCardVirtualOrder : true
        },
        ChipAndPin: {
          items: [
            'Secured In-Person payments;',
            'Stay in control of funds flow;',
            'Safeguarded with leading bank solutions;',
            'Pay your bills remotely;',
            'Extra-secured transactions;',
            'Spend abroad without the hidden fees.',
          ],
          is_visible: this.wallet ? this.wallet.permissions.canCardChipAndPinOrder : true
        },
        Disposable: {
          items: [
            'Spend online with confidence;',
            'Extra-secure transactions.'
          ],
          is_visible: this.wallet ? this.wallet.permissions.canCardDisposableOrder : true
        }
      },

      notSetLabel: 'Not set',
      submitFormInProgress: false
    }
  },
  mounted() {
    // Preload basic information
    this.$sdk.card.getInfo().then(({ data }) => {
      this.deliveries = this.setDeliveries(data.delivery.type)
      this.countries = data.delivery.country
      this.phone = data.phone
      this.cards = data.card.type.map(item => {
        return {
          ...item,
          ...this.cardsAdditional[item.id]
        }
      })
    })

    //Preload wallet
    if (this.id) {
      this.form.wallet = this.getBalanceById(this.id)
      this.form.type = 'select'
      this.step = 'card_type'
    } else {
      this.form.type = 'new'
      this.step = 'card_account'
    }
  },
  watch: {
    'form.type' (type) {
      if (type === 'new') {
        this.form.wallet = null
      } else {
        this.form.wallet_name = null
      }
    },
  },
  sockets: {
    'refreshCurrencyExchangeRate' () {
      this.$asyncComputed.tax.update()
    }
  },
  /**
   * @class {object} tax
   * @property {array} tax.card
   * @property {array} tax.delivery
   * @property {array} tax.total
   */
  asyncComputed: {
    tax () {
      return this.$sdk.card
          .getCardInfoPrice(this.taxParams)
          .then(response => response.data)
    }
  },
  computed: {
    ...mapGetters({
      activeBalances: 'getActiveBalances',
      getBalanceById: 'getBalanceById'
    }),

    taxParams () {
      return {
        card_type_id: this.form.card_type || undefined,
        delivery_type_id: this.form.delivery_type && this.form.delivery_type.id,
        currency_id: this.form.payment_wallet ? this.form.payment_wallet.currency.id : undefined,
      }
    },

    wallet () {
      return this.getBalanceById(this.id)
    },

    cardWallets () {
      return this.activeBalances.filter(i => i.isCardWallet)
    },
    hasCardWallets () {
      return this.cardWallets.length > 0
    },
    balancesList () {
      const data = []
      const items = groupBy(this.activeBalances, 'currency.id')

      for (const key in items) {
        data.push({ title: key, items: items[key] })
      }

      return data
    },

    availableCards () {
      return this.cards.filter(i => i.is_visible)
    },

    filteredMenuItems () {
      return this.menuItems.filter(i => i.isVisible() === true)
    },

    menuItems () {
      return [
        {
          name: 'Card wallet',
          step: 'card_account',
          attributes: ['wallet_id', 'wallet_description'],
          isCompleted: () => {
            return !!(
                (
                    this.form.type === 'select' &&
                    this.form.wallet &&
                    !this.errors['wallet_id']
                ) || (
                    this.form.type === 'new' &&
                    !this.errors['wallet_description']
                )
            )
          },
          isVisible: () => {
            return true
          }
        },
        {
          name: 'Card type',
          step: 'card_type',
          attributes: ['card_type'],
          isCompleted: () => {
            return !!this.form.card_type
          },
          isVisible: () => {
            return true
          }
        },
        {
          name: 'Address and delivery',
          step: 'address',
          attributes: ['country_id', 'city', 'house', 'street', 'postal_code', 'delivery_type', 'phone', 'first_name', 'last_name'],
          isCompleted: () => {
            return !!(
                this.form.delivery_country
                && this.form.delivery_city
                && this.form.delivery_house
                && this.form.delivery_street
                && this.form.delivery_type
                && this.form.delivery_postal_code
                && this.form.first_name
                && this.form.last_name
                && this.form.phone
                && (
                    this.errors
                    && !this.errors['country_id']
                    && !this.errors['city']
                    && !this.errors['house']
                    && !this.errors['street']
                    && !this.errors['postal_code']
                    && !this.errors['delivery_type']
                    && !this.errors['phone']
                    && !this.errors['first_name']
                    && !this.errors['last_name']
                )
            )
          },
          isVisible: () => {
            return this.form.card_type === 'ChipAndPin'
          }
        },
        {
          name: 'Payment',
          step: 'payments',
          attributes: ['password', 'fee_wallet_id'],
          isCompleted: () => {
            return !!(
                this.form.payment_wallet
                && this.form.static_password
                && (
                    this.errors
                    && !this.errors['fee_wallet_id']
                    && !this.errors['password']
                )
            )
          },
          isVisible: () => {
            return true
          }
        },
        {
          name: 'Review',
          step: 'finish',
          attributes: [],
          isCompleted: () => {
            return true
          },
          isVisible: () => {
            return true
          }
        }
      ]
    },

    formData () {
      return {
        wallet_id: this.form.wallet ? this.form.wallet.id : undefined,    // For type === "new"
        wallet_description: this.form.wallet_name || undefined,           // For type === "select"

        fee_wallet_id: this.form.payment_wallet && this.form.payment_wallet.id,
        type: this.form.card_type,

        first_name: this.form.first_name,
        last_name: this.form.last_name,
        phone: this.form.phone,

        street: this.form.delivery_street,
        house: this.form.delivery_house,
        city: this.form.delivery_city,
        country_id: this.form.delivery_country && this.form.delivery_country.id,
        postal_code: this.form.delivery_postal_code,
        delivery_type: this.form.delivery_type && this.form.delivery_type.id,

        password: this.form.static_password,
      }
    },

    currentStep () {
      return this.filteredMenuItems.find(i => i.step === this.step)
    },

    nextStep () {
      let item = this.getNextStep(this.step)
      return item ? item.step : 'save'
    },

    fullCompleted () {
      return !this.filteredMenuItems.some(i => i.isCompleted() === false)
    },
  },
  methods: {
    /**
     *
     * @param event
     * @param step
     */
    goNextStep: debounce(function (event, step) {
      let attributes = this.currentStep ? this.currentStep.attributes : []

      if (this.currentStep.step === 'card_account' && this.form.type === 'new') {
        this.step = step || this.nextStep
        return
      }

      this.validate(this.formData, ({ data }) => {
        this.errors = data

        let result = Object.keys(this.errors).filter(i => {
          return attributes.indexOf(i) !== -1
        })

        if (result.length === 0) {
          this.step = step || this.nextStep
          this.errors = []
        }
      })
    }, 200),

    /**
     *
     * @param step
     */
    getNextStep (step) {
      let index = this.filteredMenuItems.findIndex(i => i.step === step)
      return this.filteredMenuItems[++index]
    },

    getMenuItem (step) {
      return this.menuItems.find(i => i.step === step)
    },

    /**
     * Validate
     *
     * @param attributes
     * @param action
     * @param validate
     */
    validate (attributes = [], action = null, validate = 1) {
      let attrs   = Array.isArray(attributes) ? attributes : [attributes]
      let promise = this.$sdk.card.create(this.formData, validate)

      if (typeof action === 'function') {
        promise.then(action)
      } else {
        promise.then(({ data }) => {
          for (let attribute of attrs) {
            if (data[attribute]) {
              this.$set(this.errors, attribute, data[attribute])
            } else {
              this.$delete(this.errors, attribute)
            }
          }
        })
      }
    },

    /**
     * Submit all form
     *
     * @return {Promise<void>}
     */
    submit () {
      this.submitFormInProgress = true

      this.$modal.show(ConfirmModal, {
        beforeClose: () => this.submitFormInProgress = false,
        submit: async ({ code }) => {
          let formData = this.formData
          formData.code = code

          return this.$sdk.card.create(formData, 0).then(response => {
            const { data, headers } = response

            if(Object.keys(data).length > 0) {
              this.errors = data

              if (data.type) {
                this.$notice.error(data.type[0])
              }
            } else {
              this.$emit('close')
              this.$notice.success('The card has been successfully ordered')
            }

            return response
          }).finally(() => {
            this.submitFormInProgress = false
          })
        }
      })
    },

    moveToStep (newStep) {
      let rs = []

      for (let item of this.filteredMenuItems) {
        if (item.step === newStep) {
          break;
        } else {
          rs.push(item.isCompleted())
        }
      }

      if (rs.every(i => i === true)) {
        this.step = newStep
      } else {
        this.$notice.error('Please make sure all fields are filled out correctly')
      }
    },

    geViewCardName (type) {
      let i = this.cards.find(i => i.id === type)
      return i ? i.label + ' card': undefined
    },

    setDeliveries (list) {
      if (!list) return []
      let secInDay = 86400

      return list.map(i => {
        if (i.time.min === i.time.max) {
          i.time_label = Math.ceil(i.time.min / secInDay)
        } else {
          i.time_label = Math.ceil(i.time.min / secInDay) + '-' + Math.ceil(i.time.max / secInDay)
        }

        i.time_label += ' days'
        return i
      })
    },
  }
}
</script>


<style lang="less" scoped>
.card-action-type {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;

  .radio-or {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    opacity: .6;
  }
}

hr.dashed {
  border: none;
  height: 1px;
  width: 100%;
  background-color: #3C3C3E;

  background-image: linear-gradient(to right, #19191C 40%, rgba(255, 255, 255, 0) 10%);
  background-position: bottom;
  background-size: 15px 3px;
  background-repeat: repeat;
}

.choose-card > li > hr.dashed {
  margin-top: 40px;
}

.modal-inside {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(25, 25, 28, .92);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 2;
  height: 100%;
  width: 100%;

  padding: 30px;

  &-title {
    font-size: 20px;
    font-weight: 500;
  }

  &-subtitle {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    opacity: .6;

    margin: 10px auto 30px;
    max-width: 435px;
    text-align: center;
  }

  &-buttons {
    display: flex;
    gap: 10px;

    .default-button {
      margin: 0;
    }
  }
}

.default-button-white {
  background-color: #fff;
  border-color: #FFF;
}

.delivery {
  display: flex;

  &-main {
    font-weight: 500;
    margin-right: auto;
  }
  &-desc {
    font-size: 12px;
  }
}

.choose-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 40px;

  & > li {
    .card-item {
      display: grid;
      grid-template-columns: minmax(240px, 340px) 1fr;
      grid-gap: 30px;

      &-img {
        position: relative;
        border: 7px solid transparent;
        transition: border .1s ease;
        max-width: 359px;
        border-radius: 22px;

        svg {
          width: 100%;
          height: auto;
          display: block;
        }
      }
      &-price {
        border-radius: 13px;
        background-color: #19191C;
        color: rgba(255, 255, 255, .7);
        position: absolute;
        left: 20px;
        bottom: 20px;
        padding: 5px 8px 4px;
        font-size: 14px;
        font-weight: 500;
      }
      &-benefits {
        display: flex;
        flex-direction: column;
        gap: 18px;

        li {
          opacity: 0.7;
          color: #FFFFFF;

          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;

          transition: opacity .1s ease;
          padding-left: 30px;
          position: relative;

          &:before {
            content: "✔️";
            position: absolute;
            left: 2px;
          }
        }
      }
      &-title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;

        margin-bottom: 15px;
      }

      &:hover {
        cursor: pointer;
        //opacity: 1;

        .card-item {
          &-benefits li {
            //opacity: 1;
          }

          &-img {
            border-color: #464646;
          }
        }

        .card-checkbox {
          &-marker {
            &:after {
              opacity: 1;
            }
          }
        }
      }

      @media all and (max-width: 1200px) {
        grid-template-columns: 1fr;

        .card-item-title,
        .card-item-benefits {
          margin-left: 20px;
        }
      }
    }

    &.active {
      .card-checkbox {
        &-marker {
          &:after {
            opacity: 1;
            background-color: #FDE46A;
          }

          &:before {
            border-color: #FDE46A;
          }
        }
      }
      .card-item {
        &-title {
          color: #FDE46A;
        }

        &-benefits li {
          opacity: 1;
        }

        &-img {
          border-color: #464646;
        }
      }
    }
  }
}

.card-checkbox {
  display: inline-block;
  margin-right: 9px;
  position: relative;
  top: 3px;

  input {
    position: absolute;
    left: -99999px;
    visibility: hidden;
  }

  &-marker {
    position: relative;

    width: 17px;
    height: 17px;
    border: 1px solid rgba(255, 255, 255, .5);
    display: block;
    border-radius: 50%;
    box-sizing: border-box;

    &:after {
      content: ' ';
      display: block;
      box-sizing: border-box;
      border-radius: 50%;

      height: 11px;
      width: 11px;

      left: 2px;
      top: 2px;

      position: absolute;
      background-color: #FFFFFF;

      opacity: 0;
      transition: opacity .2s ease;
    }
  }
}

.color-palette-custom {
  position: absolute;

  top: 7px;
  right: 7px;
}

.wallet-flag::v-deep {
  position: relative;
  display: block;

  & > img { margin-right: 8px; vertical-align: middle; position: relative; top: -1px}
  & > span { font-size: 14px; vertical-align: middle; }
}

.card-review {
  display: flex;
  gap: 10px;

  svg {
    max-width: 24px;
    max-height: 20px;
  }
}

.white {
  color: #FFF;
}

.with-devider:before {
  content: ' / '
}

.st-pwd-text {
  margin-top: 10px;
  font-size: 14px;
  color: rgba(255, 255, 255, .7);
}
</style>
