<template>
  <component :is="component" :class="classList"/>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'Card',
  props: {
    type: {
      type: String,
      required: true,
      validator: (name) => {
        return ['Virtual', 'Disposable', 'ChipAndPin'].includes(name)
      }
    }
  },
  computed: {
    ...mapState({
      company: state => state.company.data,
    }),
    companyType () {
      return this.company.type.toLowerCase()
    },
    component () {
      let avCpm = {
        Virtual: () => import(`@/assets/images/order_cards/types/virtual-${this.companyType}.svg?inline`),
        Disposable: () => import(`@/assets/images/order_cards/types/disposable-${this.companyType}.svg?inline`),
        ChipAndPin: () => import(`@/assets/images/order_cards/types/chipandpin-${this.companyType}.svg?inline`),
      }

      return avCpm[this.type]
    },
    classType () {
      return this.type.toLowerCase()
    },
    classList () {
      return ['card-svg', 'card-svg-' + this.classType]
    }
  }
}
</script>
